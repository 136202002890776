/**
 *
 * Carousel - tablet
 * 
 */

// Scale up controls for tablets and up
.carousel-control
	.glyphicon-chevron-left,
	.glyphicon-chevron-right,
	.icon-prev,
	.icon-next
		width 30px
		height 30px
		margin-top -10px
		font-size 30px
	 
	.glyphicon-chevron-left,
	.icon-prev
		 margin-left -10px

	.glyphicon-chevron-right,
	.icon-next
		margin-right -10px

// Show and left align the captions
.carousel-caption
	left 20%
	right 20%
	padding-bottom 30px

// Move up the indicators
.carousel-indicators
	bottom 20px