/**
 *
 * Mixins
 *
 */
 
// VENDOR PREFIX
vendor(prop, args)
	-webkit-{prop} args
	-moz-{prop} args
	-ms-{prop} args
	{prop} args

appearance()
	vendor('appearance', arguments)

border-radius()
	vendor('border-radius', arguments)
	
hyphens()
	vendor('hyphens', arguments)

transform()
	-webkit-transform arguments
	-ms-transform arguments
	transform arguments

transition()
	vendor('transition', arguments)

transition-timing-function()
	-webkit-transition-timing-function arguments
	transition-timing-function arguments

// Flexbox
flex()
	display -webkit-box
	display -moz-box
	display -ms-flexbox
	display -webkit-flex
	display flex
	
flex-direction()
	vendor('flex-direction', arguments)
	
flex-wrap()
	vendor('flex-wrap', arguments)

align-items()
	vendor('align-items', arguments)
	-ms-flex-align arguments

justify-content()
	vendor('justify-content', arguments)
	-ms-flex-pack arguments

// Clearfix
clearfix()
	zoom 1
	&:before
	&:after
		content ""
		display table
	&:after
		clear both

// before & After Elements
hasBeforeAfter()
	position relative
	&:before, &:after
		content ""
		display block
		position absolute
		
hasBefore()
	position relative
	&:before
		content ""
		display block
		position absolute
		
hasAfter()
	position relative
	&:after
		content ""
		display block
		position absolute

support-for-ie ?= true
opacity(n)
	opacity n
	if support-for-ie
		filter unquote('progid:DXImageTransform.Microsoft.Alpha(Opacity=' + round(n * 100) + ')')
			
backface-visibility()
	-webkit-backface-visibility arguments
	backface-visibility arguments
	
perspective()
	-webkit-perspective arguments
	perspective arguments