/**
 *
 * Header - tablet
 * 
 */
 
// #header
.header-message
	font-size 12px
	line-height 18px
	text-indent -5px

.header-inner
	padding 12px 0 16px
	max-height 56px

.logo
	width 278px
	height 30px
	margin-top 0
// Button
#navToggleBtn
	top 16px

// Global Navigation
.global-nav
	margin-top 20px
.global-nav-ul
	justify-content center
	> li
		border-right 1px solid $gray
		padding 0 16px
		width 112px
		//width 160px
		&:last-child
			border-right none
		> a
			display inline