/**
 *
 * Typography - tablet
 * 
 */

/**
 * Font Size & Line Height
 */
h1, .h1
	font-size $h1
	line-height $h1-lh
h2, .h2
	font-size $h2
	line-height $h2-lh
h3, .h3
	font-size $h3
	line-height $h3-lh
h4, .h4
	font-size $h4
	line-height $h4-lh
h5, .h5
	font-size $h5
h6, .h6
	font-size $h6