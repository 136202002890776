/**
 *
 * Tablet
 *
 */

@charset "UTF-8"
@import "common/variables"
@import "common/mixins"

@import "base/tablet"
@import "typography/tablet"

// Modules
@import "module/button/tablet"
@import "module/card/tablet"
@import "module/carousel/tablet"
@import "module/form/tablet"
@import "module/grid/tablet"
@import "module/list/tablet"
@import "module/table/tablet"

// Layout
@import "layout/header/tablet"
@import "layout/sidebar/tablet"
@import "layout/footer/tablet"

// Specific CSS for each page
@import "page/all/tablet"
@import "page/index/tablet"
@import "page/post/tablet"