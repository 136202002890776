/**
 *
 * Post - tablet
 * 
 */
 
/**
 * index & archive
 */
article.flex-4
	width 50%
	margin-top 40px

.index-article-ttl
	margin-bottom 10px

/**
 * Single
 */
 
// Header
.single-header-company
	display inline
	
// Body
.single-body
	margin-top 40px
	h3
		font-size $h4
		// line-height $h4-lh
		margin 48px 0 32px
	p, ul, ol
		font-size $h5
		line-height 30px
		margin-top 32px
	img
		margin-top 16px

// Company
.company-info
	margin-top 56px
	> h2
		margin 0 -16px
		padding 16px
		
.company-info-table
	font-size $h6
	line-height $h6-lh
	margin-top 40px
	th
		text-align justify
		-moz-text-align-last justify
		text-align-last justify
		width 126px
	td
		padding-left 40px

// Footer
.single-footer
	margin-top 48px