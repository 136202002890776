/**
 *
 * Grid - tablet
 * 
 */

.container
	max-width $container-width-t // 768px
	padding-left $gutter
	padding-right $gutter
	&.narrow
		max-width 704px

[class^="col-"]
	float left
	&.alignright
		float right

for $i in (1..12)
	.col-{$i} 
		width (100 * $i / 12)%

for $j in (1..6)
	.offset-{$j}
		margin-left (100 * $j / 12)%

for $k in (1..12)
	.flex-{$k} 
		width (100 * $k / 12)%