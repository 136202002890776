/**
 *
 * Base - tablet
 * 
 */

$section-space = 80px
section
	&.padding
		padding $section-space 0
	&.padding-top
		padding-top $section-space
	&.padding-bottom
		padding-bottom 
	&.margin
		margin $section-space 0
	&.margin-top
		margin-top $section-space
	&.margin-bottom
		margin-bottom $section-space

a.anchor
	top -($header-height-d+16px)

// Block Alighment
.alignleft
	float left
.alignright
	float right

// Display Style
.mobile
	display none
	
.tablet
	display block
	&.ib
		display inline-block
	&.inline
		display inline
	&.table
		display table
	&.table-cell
		display table-cell