/**
 *
 * Front Page - tablet
 * 
 */
 
/**
 * Pickup
 */
.pickup-inner
	margin-top 32px
	padding-bottom 32px

.pickup-pic-a
	margin 0

.pickup-texts
	> h5
		line-height $h4-lh

.pickup-h3
	margin -32px auto 32px
	padding 24px 16px 32px
	max-width 320px

.pickup-ttl
	font-size 32px
	line-height 42px
	margin 0 0 32px 0
	> a
		> br
			display block

.pickup-name
	font-size $h4