/**
 *
 * Footer - tablet
 * 
 */
 
// Contact
.to-contact
	margin-top 64px
	padding-top 48px
	> p > br
		display block

.to-contact-btn
	font-size $h4
	margin-top 28px
	
// Footer
#footer
	margin-top 48px
	padding-bottom 4px

.footer-inner
	flex()
	padding 32px 0 0

// Logo
.footer-logo-a
	width 225px
	height 25px
	margin-top 1px

// Navigation
.footer-nav
	border-left 1px solid #c9c9ca
	margin-left 32px
	margin-top 0
	padding-left 8px

.footer-ul
	padding-top 4px
	> li
		margin-left 24px
		margin-bottom 0
		width initial

.copyright
	margin-top 14px