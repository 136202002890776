/**
 *
 * CSS throughout All Pages - tablet
 * 
 */
 
main
	&.index,
	&.page
		margin 0 0 64px
		
// Archive & Page Title
.archive-ttl,
.page-ttl
	font-size $h2
	line-height $h2-lh

.archive-ttl
	margin 16px 0 0

.page-ttl
	border-top 1px solid $gray
	margin 32px -16px 40px
	padding 12px 16px 11px

// Pagination
.pagination
	margin-top 64px

// Contact
.form-wrap
	max-width 640px
	margin 0 auto